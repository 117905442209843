import {
  COGNITO_IDP,
  enterpriseShouldbeVisible,
  getIdpUiPrio,
  hiddenProvidersMap,
  isStandardProvider,
  requiredUrlParams,
  GetEmailDomainIdpOutput,
} from '../constants/providers';
import { LAST_USED_PROVIDER_KEY } from '../constants/storage';
import logger from '../utils/logger';
import { IdProvider } from '../types/auth';
import { AppConfig } from '../types/app';
import { IDP_URL_PARAM, URL_PARAMS } from '../constants/auth';
import { localStorageAdapter } from './storage';
import { AppURL } from '../constants/urls';

const getIdpUrl = (idp: string, domain: string): string => {
  let hostname = domain;
  if (hostname.includes('://')) {
    const url = new URL(hostname);
    hostname = url.hostname;
  }

  const baseUrl = `https://${hostname}/oauth2/authorize`;
  const urlParams = new URLSearchParams(window.location.search);
  urlParams.set(IDP_URL_PARAM, idp);
  return `${baseUrl}?${urlParams.toString()}`;
};

const providerPrioCompare = (idp1: string, idp2: string) => {
  return getIdpUiPrio(idp1) - getIdpUiPrio(idp2);
};

type ValidatedParams = { isValid: boolean; errorMsg: string };

const validateRequiredParams = (
  urlParams: URLSearchParams,
  requiredParams: string[]
): ValidatedParams => {
  const missingParams = requiredParams.filter(
    (param) => !Boolean(urlParams.get(param))
  );

  const rtnValidParams: ValidatedParams = { isValid: true, errorMsg: '' };
  if (missingParams.length) {
    rtnValidParams.isValid = false;
    rtnValidParams.errorMsg = `Missing url paramters: [${missingParams.join(
      ', '
    )}].`;
  }
  return rtnValidParams;
};

export const transformNameToIdProvider = (
  idp: string,
  gandalfDomain: string
): IdProvider => ({
  idp,
  url: getIdpUrl(idp, gandalfDomain),
});

const getClientId = (requiredParams = requiredUrlParams) => {
  let urlParams = new URLSearchParams(window.location.search);
  if (window.location.search === '') {
    //check for localstorage
    const extractedParams = localStorage.getItem(URL_PARAMS);
    if (extractedParams) {
      urlParams = new URLSearchParams(extractedParams);
      window.location.assign(
        `${window.location.origin}${
          AppURL.Login
        }?${extractedParams?.toString()}`
      );
    }
  }
  const validatedURLParams: ValidatedParams = validateRequiredParams(
    urlParams,
    requiredParams
  );
  if (!validatedURLParams.isValid) {
    throw new Error(validatedURLParams.errorMsg);
  }
  return urlParams.get('client_id') as string;
};

const getProvidersFromConfig = (
  config: AppConfig,
  clientId: string
): IdProvider[] => {
  const providerList = config?.clients[clientId]?.idps;

  if (!providerList) {
    throw new Error('Unsupported client_id');
  }

  providerList.sort(providerPrioCompare);

  return (config.clients[clientId].idps || []).map((idp) =>
    transformNameToIdProvider(idp, config.gandalfDomain)
  );
};

const getProvider = (
  idpName: GetEmailDomainIdpOutput,
  providers: IdProvider[]
) => {
  let provider = providers.find((p) => p.idp === idpName.idp);
  if (!provider && Boolean(!isStandardProvider(idpName.idp))) {
    provider = providers.find((p) => p.idp === enterpriseShouldbeVisible);
    if (provider) {
      provider.idp = idpName.idp;
      provider.url = provider?.url.replace(
        enterpriseShouldbeVisible,
        idpName.idp
      );
      if (idpName.displayName !== undefined && idpName.displayName !== '') {
        provider.lastUsedIdpDisplayName = idpName.displayName;
      }
    }
  }
  return provider;
};

/**
 * Checks if the customer has specified a preferred IDP other than COGNITO.
 * Will also verify that the suggested IDP is in the list of available IDPs
 * for the given client ID.
 */
const getPreferredIdp = (providers: IdProvider[]): IdProvider | undefined => {
  const urlParams = new URLSearchParams(window.location.search);
  const provider = urlParams.get(IDP_URL_PARAM);

  if (!provider || provider.toLowerCase() === COGNITO_IDP) return;
  const providerEmailDomainOutput: GetEmailDomainIdpOutput = {
    idp: provider,
    displayName: provider,
  };
  return getProvider(providerEmailDomainOutput, providers);
};

const getLastUsedProvider = (
  providers: IdProvider[]
): { lastProvider: IdProvider; createdAt: number } | null => {
  const obj = getLastUsedProviderFromLocalStorage();
  if (!obj) return null;

  // Verify that the last used IDP is in the list of supported IDPs for the client.
  if (!getProvider(obj.idp, providers)) {
    logger.debug('Last used provider is not in the list of supported IDPs');
    return null;
  }

  return {
    lastProvider: { ...obj.idp, url: getIdpUrl(obj.idp.idp, obj.idp.url) },
    createdAt: obj.createdAt,
  };
};

const setLastUsedProvider = (idp: IdProvider, createdAt = Date.now()) => {
  // don't store URL query parameters
  let url = idp.url;
  if (url.indexOf('?') !== -1) {
    url = url.substring(0, url.indexOf('?'));
  }

  localStorage.setItem(
    LAST_USED_PROVIDER_KEY,
    JSON.stringify({
      idp: { ...idp, url: url },
      createdAt,
    })
  );
};

const isVisibleProvider = (provider: IdProvider) => {
  // Enterprise providers should not be visible in the UI.
  // AmazonSystemFederate should not be visible in the UI.
  // GandalfSession should not be visible in the UI.
  return Boolean(
    isStandardProvider(provider.idp) && !hiddenProvidersMap[provider.idp]
  );
};

// Checks if the used client has a enterprise provider enabled.
function shouldShowEnterpriseChoice(providers: IdProvider[]) {
  return Boolean(
    providers.find((provider) => !isStandardProvider(provider.idp))
  );
}

function isCreatedAtTooOld(createdAt: number): boolean {
  const FIFTY_FIVE_MINUTES = 55 * 60 * 1000;
  return Date.now() > createdAt + FIFTY_FIVE_MINUTES;
}

function resetLastUsedProviderCreatedAt() {
  const obj = getLastUsedProviderFromLocalStorage();
  if (!obj) return null;

  // Set the createdAt date to 1969 so that it is considered "old".
  setLastUsedProvider(obj.idp, 1);
}

function getLastUsedProviderFromLocalStorage(): {
  idp: IdProvider;
  createdAt: number;
} | null {
  const value = localStorageAdapter.getItem(LAST_USED_PROVIDER_KEY);
  if (!value) {
    logger.debug('No last used provider storage');
    return null;
  }

  let obj: any;
  try {
    obj = JSON.parse(value);
  } catch {
    logger.debug('Last used provider storage is not valid JSON');
    return null;
  }

  const idp: IdProvider = obj.idp;
  const createdAt: number = obj.createdAt;
  if (!idp) {
    logger.debug('Last used provider storage malformed');
    return null;
  }

  return { idp, createdAt };
}

const providersService = {
  getIdpUrl,
  getClientId,
  getProvider,
  getProvidersFromConfig,
  getPreferredIdp,
  transformNameToIdProvider,
  getLastUsedProvider,
  setLastUsedProvider,
  isVisibleProvider,
  shouldShowEnterpriseChoice,
  isCreatedAtTooOld,
  resetLastUsedProviderCreatedAt,
};

export default providersService;
