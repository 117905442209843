import React, { JSX } from 'react';
import {
  Box,
  Button,
  Header,
  Modal,
  SpaceBetween,
  TextContent,
} from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';
import restrictICantFlowMessages from '../i18n/restrictICantFlow.messages';

const RestrictIcantFlowComponent = ({
  setParentVisibleState,
}: {
  setParentVisibleState: (isVisible: boolean) => void;
}): JSX.Element => {
  const [visible, setVisible] = React.useState(true);
  const { formatMessage } = useIntl();
  if (visible) {
    return (
      <Modal
        onDismiss={() => {
          setVisible(false);
          setParentVisibleState(false);
        }}
        visible={visible}
        header={
          <Header>
            <Header>{formatMessage(restrictICantFlowMessages.header)}</Header>
          </Header>
        }
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                variant="primary"
                onClick={(e) => {
                  e.preventDefault();
                  setVisible(false);
                  setParentVisibleState(false);
                }}
              >
                Okay
              </Button>
            </SpaceBetween>
          </Box>
        }
      >
        <TextContent>
          {formatMessage(restrictICantFlowMessages.bodyConent, {
            supportLink: (chunks) => (
              <a
                href="https://support.aws.amazon.com/#/contacts/aws-training"
                target="_blank"
                rel="noopener noreferrer"
              >
                {chunks}
              </a>
            ),
          })}
        </TextContent>
      </Modal>
    );
  } else {
    return <></>;
  }
};

export default RestrictIcantFlowComponent;
