import { defineMessages } from 'react-intl';

const restrictICantFlowMessages = defineMessages({
  header: {
    id: 'restricIcantFlow.header',
    defaultMessage: 'Contact AWS Training & Certification',
    description: 'Header for Restrict User I Cant help',
  },
  bodyConent: {
    id: 'restricIcantFlow.body',
    defaultMessage:
      'If you are unable to validate your existing profile. Please submit a ticket to the <supportLink>AWS Training & Certification customer support team</supportLink>, and they will provide assistance.',
    description: 'Body for Restrict User I Cant help',
  },
  goBackButton: {
    id: 'restricIcantFlow.goBackButton',
    defaultMessage: 'Go Back',
    description: 'Go back button',
  },
});

export default restrictICantFlowMessages;
