import { useEffect, useRef, useState } from 'react';
import * as KatalMetrics from '@amzn/katal-metrics';

import useGetPublicIdpDetails from './useGetPublicIdpDetails';
import { isEltiProvider } from '../constants/providers';
import providersService from '../services/providers';
import metricsService from '../services/metrics';
import { retry } from '../utils/promise';
import logger from '../utils/logger';
import { IdProvider } from '../types/auth';
import { AppConfig } from '../types/app';
import { rumService } from '../services/rum';

export enum UIState {
  loading = 'loading',
  fullUi = 'fullUi',
  showLastUsedProvider = 'showLastUsedProvider',
  preferredIdpNotFound = 'preferredIdpNotFound',
  preferredIdpError = 'preferredIdpError',
}

type SignInState = {
  uiState: UIState;
  context: {
    /**
     * Used to populate a suggested provider in the UI.
     */
    suggestedProvider?: IdProvider;
  };
};

/**
 * The called API will check if Gandalf has a active session cookie,
 * if it does it means there is a active session we can re-use for a smooth
 * sign in experience, or SSO.
 *
 * @param apiPath Path to the session API.
 */
async function getSessionState(apiPath: string) {
  const metricNs = 'GetSessionState';
  const publisher = metricsService.getPublisher(metricNs);
  const timerMetric = metricsService
    .createTimerStopWatch(`${metricNs}Time`)
    .withMonitor();

  return retry(() => fetch(apiPath))
    .then((res: Response) => res.json())
    .then((data: any) => {
      publisher.publishCounterMonitor(`${metricNs}Success`, 1);
      return data;
    })
    .catch((error: Error) => {
      publisher.publishCounterMonitor(`${metricNs}Error`, 1);
      publisher.publishStringTruncate(`${metricNs}ErrorMsg`, error.toString());
      throw error;
    })
    .finally((data: any) => {
      publisher.publish(timerMetric);
      return data;
    });
}

function useSignIn(
  config: AppConfig,
  providers: IdProvider[],
  metricsPublisher: KatalMetrics.Publisher,
  onRedirectToIdp: { (provider: IdProvider): void }
): SignInState {
  const [uiState, setUiState] = useState(UIState.loading);
  const [suggestedProvider, setSuggestedProvider] = useState<
    IdProvider | undefined
  >();

  const {
    error: getPreferredIdpDetailsError,
    result: getPreferredIdpDetailsResult,
    getPublicIdpDetails: getPreferredIdpDetails,
  } = useGetPublicIdpDetails(config, providers);
  useEffect(() => {
    if (getPreferredIdpDetailsResult?.provider) {
      onRedirectToIdp(getPreferredIdpDetailsResult.provider);
    }
  }, [getPreferredIdpDetailsResult, config, onRedirectToIdp]);
  useEffect(() => {
    if (getPreferredIdpDetailsResult?.isFound === false) {
      setUiState(UIState.preferredIdpNotFound);
      metricsPublisher.publishCounterMonitor('PreferredIdpNotFound', 1);
    }
  }, [getPreferredIdpDetailsResult, metricsPublisher]);
  useEffect(() => {
    if (getPreferredIdpDetailsError) {
      setUiState(UIState.preferredIdpError);
      metricsPublisher.publishCounterMonitor('GetPreferredIdpDetailsError', 1);
      metricsPublisher.publishStringTruncate(
        'ErrorMsg',
        getPreferredIdpDetailsError.toString()
      );
      metricsPublisher.publishStringTruncate('UserAgent', navigator.userAgent);
    }
  }, [getPreferredIdpDetailsError, metricsPublisher]);

  const lastUsedIdpCreatedAt = useRef(0);

  const {
    error: getLastUsedIdpDetailsError,
    result: getLastUsedIdpDetailsResult,
    getPublicIdpDetails: getLastUsedIdpDetails,
  } = useGetPublicIdpDetails(config, providers);
  useEffect(() => {
    if (getLastUsedIdpDetailsResult?.provider) {
      const idp = getLastUsedIdpDetailsResult.provider;
      if (
        // ELTI type providers are not eligible for reuse when signing in
        // manually. We no longer save ELTI providers as the last used
        // provider, but for cases where a user already had one saved
        // in their local storage, this logic ensures we do not use it.
        isEltiProvider(idp.idpType)
      )
        return setUiState(UIState.fullUi);
      setSuggestedProvider(idp);

      if (
        // Check if should show the last used provider window. `enableShowLastUsedProvider` is an escape hatch in
        // case we'd like to turn it off.
        config.enableShowLastUsedProvider !== 'true'
      )
        return setUiState(UIState.fullUi);

      if (
        // Check if SSO is enabled. `enableAuthSessionReuse` is an escape hatch in
        // case we'd like to turn it off.
        config.enableAuthSessionReuse !== 'true' ||
        // Check if the createdAt date for the last used provider is "younger" than
        // that to avoid unneccessary API calls. Cogito stores a session cookie on
        // the auth domain which exists for 1 hour.
        providersService.isCreatedAtTooOld(lastUsedIdpCreatedAt.current)
      )
        return setUiState(UIState.showLastUsedProvider);

      // Check if there is an active sign in session.
      getSessionState(config.sessionApiPath)
        .then((hasActiveSession: boolean) => {
          if (hasActiveSession) {
            logger.debug('There is a active session. Redirect to auth.');
            onRedirectToIdp(idp);
            setUiState(UIState.loading);
            metricsPublisher.publishCounterMonitor('SSO:SessionStateActive', 1);
          } else {
            logger.debug('No active session.');
            setUiState(UIState.showLastUsedProvider);
            metricsPublisher.publishCounterMonitor(
              'SSO:SessionStateInactive',
              1
            );
          }
        })
        .catch((error: Error) => {
          logger.debug('Get session error:', error);
          setUiState(UIState.showLastUsedProvider);
          metricsPublisher.publishCounterMonitor('SSO:SessionStateError', 1);
          metricsPublisher.publishStringTruncate(
            'SSO:SessionStateErrorUserAgent',
            navigator.userAgent
          );
        });
    }
  }, [
    lastUsedIdpCreatedAt,
    getLastUsedIdpDetailsResult,
    config,
    setSuggestedProvider,
    metricsPublisher,
    onRedirectToIdp,
  ]);
  useEffect(() => {
    if (getLastUsedIdpDetailsResult?.isFound === false) {
      setUiState(UIState.fullUi);
      metricsPublisher.publishCounterMonitor('LastUsedIdpNotFound', 1);
    }
  }, [getLastUsedIdpDetailsResult, metricsPublisher]);
  useEffect(() => {
    if (getLastUsedIdpDetailsError) {
      setUiState(UIState.fullUi);
      metricsPublisher.publishCounterMonitor('GetLastUsedIdpDetailsError', 1);
      metricsPublisher.publishStringTruncate(
        'ErrorMsg',
        getLastUsedIdpDetailsError.toString()
      );
      metricsPublisher.publishStringTruncate('UserAgent', navigator.userAgent);
    }
  }, [getLastUsedIdpDetailsError, metricsPublisher]);

  // This effect is run only once on initiation to set the base sate. It
  // also triggers redirects to IDPs depending on the found state.
  useEffect(() => {
    // Check if the customer has provided a preferred IDP through the URL.
    // In that case we'll redirect immediately; this is the same behavior
    // as Cognito Hosted UI.
    const preferredIdp = providersService.getPreferredIdp(providers);
    if (preferredIdp) {
      const urlParams = new URLSearchParams(window.location.search);
      const clientId = urlParams.get('client_id') || 'NA';
      rumService.recordEvent('preferred_idp_signIn', {
        PreferredIdp: preferredIdp,
        ClientId: clientId,
      });
      metricsPublisher.publishCounterMonitor('PreferredIdpSignIn', 1);
      metricsPublisher.publishCounterMonitor(
        `PreferredIdp:${preferredIdp.idp}`,
        1
      );
      setUiState(UIState.loading);

      if (config.enableGandalfSession) {
        // we need to get the IDP details (it may be in a different User Pool)
        getPreferredIdpDetails(preferredIdp.idp); // async API call; sets `getPreferredIdpDetailsResult` on success, triggering the redirect
      } else {
        // we assume the IDP is in the "main" User Pool
        onRedirectToIdp(preferredIdp);
      }

      return;
    }

    const { lastProvider, createdAt } =
      providersService.getLastUsedProvider(providers) || {};
    if (createdAt) {
      lastUsedIdpCreatedAt.current = createdAt;
    }

    if (!lastProvider || !createdAt) return setUiState(UIState.fullUi);

    getLastUsedIdpDetails(lastProvider.idp); // async API call; triggers remaining logic on completion
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    uiState,
    context: { suggestedProvider },
  };
}

export default useSignIn;
