import { defineMessages } from 'react-intl';

const suspendedAccountMessages = defineMessages({
  header: {
    id: 'suspendedAccount.header',
    defaultMessage: 'Suspended account',
    description: 'Header for Suspended Accoount',
  },
  messageBody: {
    id: 'suspendedAccount.bodyContent',
    defaultMessage:
      'Your account has been suspended due to unusual activity. If you believe this is in error, contact customer service.',
    description: 'Body content for the Suspended account',
  },
  returnToSignInPageButton: {
    id: 'suspendedAccount.returnToSignInPageButton',
    defaultMessage: 'Return to sign in',
    description: 'Return to sign in page',
  },
});

export default suspendedAccountMessages;
