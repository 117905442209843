import { SyntheticEvent, useEffect, useState, useRef } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  Alert,
  Button,
  Container,
  Form,
  FormField,
  Header,
  Input,
  SpaceBetween,
} from '@amzn/awsui-components-react';

import { AppURL } from '../constants/urls';
import challengeMessages from '../i18n/answerChallenge.messages';
import {
  createCognitoUser,
  answerCustomChallenge,
  keepSessionOpen,
  startSession,
  getResponseUrl,
  globalSignOut,
} from '../services/emailOTPAuth';
import { UserAndAuthStatus } from '../shared/userAndAuthStatus.interface';
import { AppConfig } from '../types/app';
import logger from '../utils/logger';

import styles from './CardContainer.module.css';
import React from 'react';

export const AnswerChallengeForm = ({
  config,
  headerMsg,
  descriptionMsg,
  showBackButton,
  useUser,
}: {
  config: AppConfig;
  headerMsg: MessageDescriptor;
  descriptionMsg: MessageDescriptor;
  showBackButton: boolean;
  useUser?: { userName: string; session: string; email: string };
}): JSX.Element => {
  const { formatMessage } = useIntl();
  const location = useLocation();
  const navigate = useNavigate();
  const hState = location.state;
  const errorMessage = formatMessage(challengeMessages.errorMessage);

  const refCognitoUser = useRef(undefined);
  let cognitoUserName = '';
  let cognitoSession = '';
  let userEmail = '';
  try {
    cognitoUserName = useUser
      ? useUser.userName
      : (hState as any).cognitoUserName;
    cognitoSession = useUser ? useUser.session : (hState as any).cognitoSession;
    userEmail = useUser ? useUser.email : (hState as any).userEmail;
  } catch (err) {
    throw new Error('No valid session.');
  }

  const keepAliveIntervalMs = 150000; // 2.5 minutes
  const keepAliveMaxAttempts = 6; // keep Cognito session alive for at least 15 minutes
  const [keepAliveCount, setKeepAliveCount] = useState(0);
  const [keepAliveTimer, setKeepAliveTimer] = useState(0);

  const [loading, setLoading] = useState(false);
  const [otpEntered, setOtpEntered] = useState(false);
  const [otpValue, setOtpValue] = useState('');
  const [passCodeError, setPassCodeError] = useState('');
  const [successMessages, setSuccessMessages] = useState(['']);

  const successAlertBar = successMessages
    .filter((msg) => msg)
    .map((msg) => (
      <Alert
        key={msg}
        type="success"
        dismissible={true}
        onDismiss={() =>
          setSuccessMessages(
            successMessages.filter((innerMsg) => innerMsg !== msg)
          )
        }
      >
        {msg}
      </Alert>
    ));

  const isNineDigitCode = (input: string) => {
    const nineDigitRegExp = new RegExp('^[0-9]{9}$');
    return nineDigitRegExp.test(input);
  };

  const startKeepAlive = (reset = false) => {
    if (reset) {
      setKeepAliveCount(0);
    } else if (keepAliveCount >= keepAliveMaxAttempts) {
      return;
    }

    const timer = window.setInterval(async () => {
      try {
        refCognitoUser.current = (await keepSessionOpen(
          refCognitoUser.current
        )) as any;
      } catch (e) {
        // encountered an error; assume session has timed out
        stopKeepAlive();
      }

      setKeepAliveCount((prevCount) => prevCount + 1);
    }, keepAliveIntervalMs);
    setKeepAliveTimer(timer);
  };

  const stopKeepAlive = () => {
    window.clearInterval(keepAliveTimer);
  };

  const handleSendSecretCode = async (e?: CustomEvent) => {
    e?.preventDefault();

    if (!isNineDigitCode(otpValue)) {
      setPassCodeError(errorMessage);
      return;
    }
    try {
      setLoading(true);
      stopKeepAlive();
      const resultOfCustomChallenge: UserAndAuthStatus =
        await answerCustomChallenge(otpValue, refCognitoUser.current);
      refCognitoUser.current = resultOfCustomChallenge.cognitoUser as any;
      setLoading(false);
      if (resultOfCustomChallenge.isAuthenticated) {
        setLoading(true);
        setSuccessMessages(
          successMessages.concat(
            formatMessage(challengeMessages.successMessage)
          )
        );

        const urlParams = new URLSearchParams(location.search);
        const redirectUriParam = urlParams.get('redirect_uri');
        const stateParam = urlParams.get('state');
        const idToken = resultOfCustomChallenge.cognitoUser
          .getSignInUserSession()!
          .getIdToken()
          .getJwtToken();
        const accessToken = resultOfCustomChallenge.cognitoUser
          .getSignInUserSession()!
          .getAccessToken()
          .getJwtToken();

        const responseUrl = await getResponseUrl(
          config,
          idToken,
          accessToken,
          redirectUriParam!,
          stateParam!
        );
        await globalSignOut();
        window.location.assign(responseUrl);
      } else {
        setPassCodeError(errorMessage);
        startKeepAlive();
      }
    } catch (err) {
      // Number of allowed wrong attempts exceeded or session expired
      logger.error(err);
      if (useUser) {
        // For email verification, send another OTP email
        resendPasscode();
      } else {
        // For OTP sign-in, prompt the user to enter their email again
        navigate(`${AppURL.OTPInput}${location.search}`, {
          state: {
            sessionExpired: true,
            userEmail: userEmail,
          },
        });

        navigate(AppURL.OTPInput);
      }
    }
  };

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    await handleSendSecretCode();
  };

  const resendPasscode = async () => {
    try {
      setLoading(true);
      stopKeepAlive();
      refCognitoUser.current = (await startSession(userEmail)) as any;
      startKeepAlive(true);
      setLoading(false);
      setSuccessMessages(
        successMessages.concat(
          formatMessage(challengeMessages.resendMessage, { email: userEmail })
        )
      );
    } catch (err) {
      logger.error(err);
    }
  };

  const handleResendPasscode = async () => {
    await resendPasscode();
  };

  const handleCodeChange = (e: any) => {
    if (isNineDigitCode(e.detail.value)) {
      setOtpEntered(true);
      setPassCodeError('');
    } else {
      setOtpEntered(false);
    }
    setOtpValue(e.detail.value);
  };

  const getUser = async () => {
    refCognitoUser.current = (await getCognitoUser()) as any;
  };

  const getCognitoUser = async () => {
    if (cognitoUserName && cognitoSession) {
      const cognitoUser = await createCognitoUser(
        cognitoUserName,
        cognitoSession
      );
      return cognitoUser;
    } else {
      //TODO: User should not be clicking on button without a session
    }
  };

  useEffect(() => {
    // when component mounts, we must get the current Cognito user
    if (!refCognitoUser.current) {
      getUser();
    }

    if (keepAliveTimer === 0) {
      startKeepAlive();
    }

    if (keepAliveCount >= keepAliveMaxAttempts) {
      stopKeepAlive();
    }
  });

  return (
    <>
      <Container
        header={
          <Header
            className={styles.header}
            variant="h2"
            headingTagOverride="h1"
          >
            {formatMessage(headerMsg)}
          </Header>
        }
      >
        <form onSubmit={handleSubmit}>
          <Form
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                {showBackButton && (
                  <Button
                    data-testid="back-button"
                    onClick={() => {
                      stopKeepAlive();
                      navigate(-1);
                    }}
                    formAction="none"
                  >
                    {formatMessage(challengeMessages.previousButtonLabel)}
                  </Button>
                )}
                <Button
                  data-testid="submit-button"
                  variant="primary"
                  formAction="submit"
                  disabled={!otpEntered}
                  loading={loading}
                >
                  {formatMessage(challengeMessages.buttonLabel)}
                </Button>
              </SpaceBetween>
            }
          >
            <SpaceBetween direction="vertical" size="s">
              <div>
                {formatMessage(descriptionMsg, {
                  userEmail: userEmail,
                })}
              </div>
              <FormField
                label={
                  <span>
                    {formatMessage(challengeMessages.passcodeInputName)}
                  </span>
                }
                info={
                  <Button
                    href="#/"
                    variant="link"
                    onClick={(e) => {
                      e.preventDefault();
                      handleResendPasscode();
                    }}
                  >
                    {formatMessage(challengeMessages.resendPasscodeLabel)}
                  </Button>
                }
                errorText={passCodeError}
                stretch={true}
              >
                <Input
                  name="secretCode"
                  type="text"
                  data-testid="text-input"
                  onChange={(event) => {
                    handleCodeChange(event);
                  }}
                  disabled={loading}
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus={true} // OK to use since this is the only purpose of the page.
                  ariaRequired={true}
                  value={otpValue}
                />
              </FormField>
            </SpaceBetween>
          </Form>
        </form>
      </Container>
      <div>{successAlertBar}</div>
    </>
  );
};
