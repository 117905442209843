import { PostAuthComponentParams } from '../../types/auth';
import Main from '../Main';
import ContainerLayout from '../ContainerLayout';
import {
  Alert,
  Button,
  Container,
  Form,
  Header,
  SpaceBetween,
  TextContent,
} from '@amzn/awsui-components-react';
import profileDeactivatedMessages from '../../i18n/profileDeactivated.messages';
import React from 'react';
import { useIntl } from 'react-intl';

const ProfileDeactivated = ({
  user,
  config,
  onContinue,
  onCancel,
}: PostAuthComponentParams): JSX.Element => {
  const { formatMessage } = useIntl();
  if (config.enableShowProfileDeactivatedScreen !== 'true') {
    onContinue();
  }
  if (user.deactivated !== true) {
    onContinue();
    return <></>;
  }
  return (
    <>
      <Main config={config}>
        <ContainerLayout>
          <Container
            header={
              <Header variant={'h2'}>
                {formatMessage(profileDeactivatedMessages.containerTitle)}
              </Header>
            }
          >
            <Form
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      onCancel();
                    }}
                    data-testid={'return-to-signin'}
                    variant={'primary'}
                  >
                    {formatMessage(
                      profileDeactivatedMessages.returnToSignInButton
                    )}
                  </Button>
                </SpaceBetween>
              }
            >
              <SpaceBetween direction="vertical" size="xs">
                <TextContent>
                  {formatMessage(profileDeactivatedMessages.description)}
                </TextContent>
                <Alert type={'info'}>
                  {formatMessage(
                    profileDeactivatedMessages.personalBuilderIdRecommendation
                  )}
                </Alert>
              </SpaceBetween>
            </Form>
          </Container>
        </ContainerLayout>
      </Main>
    </>
  );
};

export default ProfileDeactivated;
